import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {

  static values = {
    init: String,
    togglerClass: String,
    togglerId: String,
    baseRatingId: Number,
    protected: Boolean
  }

  initialize() {
    super.initialize();
    const baseRatingId = this.baseRatingIdValue
    // console.log("baseRatingId in initializer is: ", baseRatingId)

    this.initializers = {
      sortables: this.initializeSortables,
      droppables: this.initializeDroppables,
      select2: this.initializeSelect2,
      profile_search: this.initializeProfileSearch
    }
    this.listenersSet = false
  }

  connect() {
    super.connect();
    const self = this
    const initMethods = this.initValue.split(" ");

    initMethods.forEach(function (initializer, index) {
      console.log("processing initializer: ", initializer)
      if (initializer == "") {return};
      self.initializers[initializer].bind(self)()
    })

    this.setupTooltips();
  } 

  ///
  // initializes the search triggered by a core skill selection or a skill deletion
  ///
  initializeProfileSearch() {
    // setup profile array for selected profiles
    this.selectedProfiles = []
  }

  ///
  // initializes the select2 behavior for the hard skills modal
  ///
  initializeSelect2() {
    const self = this
    $("#hard_category_selection").select2({
      placeholder: I18n.t('shared.choose_category'),
      dropdownParent: $("#hard_skill_modal")
    })
    $('#hard_category_selection').on('select2:select', function (e) {
      e.preventDefault()
      var data = e.params.data;
      // console.log(data);
      const category = data.text;
      // console.log(`... ${category}`)

      $.ajax({
        type: "GET",
        url: `skill_categories/hard_skill_subcategories.json?category=${category}`,
        success: (data) => {
          // console.log(`subcategories: ${data}`)
          const $hardSubcategorySelect = $('#hard_subcategory_selection')
          $hardSubcategorySelect.find("option").remove();
          if($hardSubcategorySelect.hasClass("select2-hidden-accessible")) {
            $hardSubcategorySelect.off('select2:select')
            $hardSubcategorySelect.select2('destroy');
          }
          $hardSubcategorySelect.select2({
            placeholder: I18n.t('shared.choose_subcategory'),
            data: data
          })
          $hardSubcategorySelect.removeClass('hidden')
          $hardSubcategorySelect.on('select2:select', function (e) {
            var data = e.params.data;
            const subcategory = data.text;
            // console.log(`subcategory: ${subcategory}`)
            $("#hard_skill_import_button").prop('disabled', false)
          });      
        }
      })

    });
  }

  ///
  // initializes the #skill_tree skill list, to get the sortable/drag behavior
  ///
  initializeSortables() {
    const self = this
    
    $("#skill_tree .skills").each(function( index ) {
      // console.log("sortable: ", this)
      Sortable.create(this, {
        group: {
          name: "skills",
          pull: 'clone',
          put: false
        },
        sort: false,
        draggable: ".skill:not(.disabled)",
        onRemove: function (event) {
          const $skillClone = $(event.clone);
          $skillClone.addClass('disabled')
        },
      })
    });

  }

  ///
  // initializes the #skill_rating skill list, to get the drop behavior
  ///
  initializeDroppables() {
    const self = this
    const baseRatingId = self.baseRatingIdValue
    // console.log("baseRatingId in droppable initializer is: ", baseRatingId)


    $(".skill-rating .skills").each(function( index ) {
      Sortable.create(this, {
        group: "skills",

        onAdd: function (/**Event*/evt) {
          const skillId = $(evt.item).data("id")
          // const levelName = $(evt.to).attr('id')

          const levelIndex = $(evt.to).data('level')
          const position = evt.newIndex
          // console.log(`added: ${skillId} on index ${position}`)
          self.stimulate('Skill#add_skill_to_level', skillId, baseRatingId, levelIndex, position, self.protectedValue).then(() => {
            document.dispatchEvent(new CustomEvent('skills:changed', { detail: { skill_rating_id: baseRatingId } }));
          })

        },
        // onSort: function (evt) {
        //   console.log('sorted')
        // },
        onUpdate: function (/**Event*/evt) {
          const $skill = $(evt.item)
          const skillSetSkillId = $skill.data("skill-set-skill-id")
          const position = evt.newIndex
          // console.log(`updated skill with skill-set-skill-id '${skillSetSkillId}' to new index: ${position}`)
          self.stimulate('Skill#reposition_skill_on_level', skillSetSkillId, baseRatingId, position).then(() => {
            document.dispatchEvent(new CustomEvent('skills:changed', { detail: { skill_rating_id: baseRatingId } }));
          })
        },

      })
    });
  }

  ///
  // shows the regarding input fields to add a new skill category
  ///
  showSkillCategoryInputs(event) {
    let $target = $(event.currentTarget);
    let parentCatId = this.data.get("categoryParentId")    
    // console.log("parent id: ", parentCatId)
    
    let li = $target.closest("li")
    // console.log("li: ", li)
    li.find("> .category-form-wrapper").toggleClass("hidden")

  }

  ///
  // adds a skill category to the hierarchy
  ///
  addSkillCategory(event) {
    let $target = $(event.currentTarget);
    let parentCatId = this.data.get("categoryParentId")
    // console.log("parent id: ", parentCatId)

    let $formWrapper = $target.closest("li").find(".category-form-wrapper")
    $formWrapper.hide()
    let title = $formWrapper.find("input[name=title]").val()
    // console.log("title: ", title)

    this.stimulate("SkillsReflex#add_skill_category", title, parentCatId).then(() => {
      this.stopLoading();
    });
  }

  ///
  // called when the skill tree toggle button is clicked to select another main category
  ///
  toggleMain(event) {
    // console.log("toggled main: ", event)
    // event.preventDefault();
    
    const $target = $(event.currentTarget);
    const $input = $target
    const catId = $input.val()
    // console.log(`toggled to `, catId);
    const options = {left: 'hard', right: 'soft'}
    const main_id = `#${this.togglerIdValue}${options[catId]}`
    // "#main_skill_accordion_" + options[catId]
    // console.log(`main id: ${main_id}`)
    const $mainCategory = $(main_id)
    // console.log("main category: ", $mainCategory)
    $(`.${this.togglerClassValue}`).addClass("hidden")
    $mainCategory.removeClass("hidden")
  }

  ///
  // removes the skill from current skill set
  ///
  removeSkill(event) {
    // event.preventDefault();
    const self = this
    const $target = $(event.currentTarget);
    // console.log("target: ", $target)
    const $skill = $target.closest(".skill")
    const skillId = $skill.data("id")
    const skillSetId = $skill.closest(".skills").data("skill-set-id")
    // console.log(`skillId: `, skillId);
    // console.log(`skillSetId: `, skillSetId);
    this.startLoading()
    this.stimulate('Skill#remove_skill_from_level', skillId, skillSetId).then(() => {
      //  document.dispatchEvent(new CustomEvent('skill:removed', { detail: { skillId: skillId, skillSetId: skillSetId } }));
      document.dispatchEvent(new CustomEvent('skills:changed'));
      $skill.remove()
      self.stopLoading()
    })
    // trigger skills changed event
  }

  toggle_core_skill(event) {
    event.preventDefault();
    // console.log("toggle core skill: ", event)
    const $target = $(event.currentTarget);
    const $skill = $target.closest(".skill")
    const skillId = $skill.data("id")
    const skillSetId = $skill.closest(".skills").data("skill-set-id")
    this.stimulate('Skill#toggle_core_skill', skillId, skillSetId).then(() => {
      document.dispatchEvent(new CustomEvent('skills:changed'));
    })
    $target.toggleClass('active')
  }

  ///
  // called when the skill rating toggle button is clicked to select another layout
  ///
  toggleRatingLayout(event) {
    // console.log("toggled main: ", event)                                                                                                                           
    event.preventDefault();
    const self = this;
    const $target = $(event.currentTarget);
    // console.log("target:", $target)
    const $input = $target
    const options = {left: 'list', right: 'confirmed'}
    const layoutIndex = $input.val()
    // console.log("layoutIndex: ", layoutIndex)
    const layout = options[layoutIndex]
    // console.log(`toggled to `, layout);
    this.stimulate('Skill#toggle_rating_layout', layout, this.baseRatingIdValue).then(() => {
      // console.log("submitted form")
      self.reloadTooltips();
    });  
  }

  ///
  // called when the skill rating should change between source and target on rating_development component
  ///
  toggleRating(event) {
    // console.log("toggled main: ", event)
    event.preventDefault();
    
    const $target = $(event.currentTarget);
    const $input = $target
    const options = {left: 'source', right: 'target'}
    const ratingIndex = $input.val()
    const rating = options[ratingIndex]
    // console.log(`toggled to `, rating);
    this.stimulate('Skill#toggle_rating', rating, this.baseRatingIdValue)  
  }

  ///
  // starts a profile search with a given rating
  ///
  profile_search(event) {
    const self = this
    this.selectedProfiles = []
    // console.log("profile search...")
    event.preventDefault();
    // const baseRatingId = event.detail.skill_rating_id;
    // console.log("event", event)
    // console.log("baseRatingId", baseRatingId)

    const $resultArea = $("#profile_search")

    const $searchButton = $("#search_button")
    $searchButton.attr("disabled", "true")
    const baseRatingId= this.baseRatingIdValue
    // console.log("baseRatingId", baseRatingId)
    const $data = $resultArea.find(".data").first()
    // console.log("data", $data)
    const gradeMatching = $data.data('grade-matching')
    // console.log("grade matching", gradeMatching)
    const performerRange = $data.data('performer-range')
    const context = this.getParam('context')
    this.startLoading()
    this.stimulate('Skill#profile_search', baseRatingId, gradeMatching, performerRange, context).then(() => {
      $searchButton.attr("disabled", null)
      // compute the width of profile cards to be able to scroll them
      const $skillsArea = $("#skills")
      const $profilesArea = $("#profiles")
      const profilesAreaWidth = $resultArea.width - $skillsArea.width
      $profilesArea.css({ width: profilesAreaWidth })

  
      // setup listeners for skills changes
      // if (!self.listenersSet) {        
      //   $skillRating.on("skills:changed", function (event) {
      //     self.profile_search(event)
      //   });
      //   self.listenersSet = true;
      // } // --> now handled in a stimulus action on edit page of skill_profiles
      $('[data-bs-toggle="tooltip"]').tooltip();

      self.stopLoading()
    });
  }

  ///
  // increases the search space
  ///
  profile_search_more_overperformer(event) {
    event.preventDefault();
    // console.log("profile more overperformer...")
    this.profile_search_more_performer("over")
  }

  ///
  // decreases the search space
  ///
  profile_search_more_underperformer(event) {
    event.preventDefault();
    // console.log("profile more underperformer...")
    this.profile_search_more_performer("under")
  }

  ///
  // increases/decreases the search space
  ///
  profile_search_more_performer(direction) {
    const self = this
    this.selectedProfiles = []

    const baseRatingId= this.baseRatingIdValue
    // console.log("baseRatingId", baseRatingId)
    const $resultArea = $("#profile_search")
    const $data = $resultArea.find(".data").first()
    const skillMatching = $data.data('skill-matching')
    const gradeMatching = $data.data('grade-matching')
    const performerRange = $data.data('performer-range') || 0
    const $searchButton = $("#search_button")
    const context = this.getParam('context')
    $searchButton.attr("disabled", "true")
    this.startLoading()
    this.stimulate('Skill#profile_search_performers', baseRatingId, skillMatching, gradeMatching, performerRange, direction, context).then(() => {
      $searchButton.attr("disabled", null)
      // compute the width of profile cards to be able to scroll them
      const $skillsArea = $("#skills")
      const $profilesArea = $("#profiles")
      const profilesAreaWidth = $resultArea.width - $skillsArea.width
      $profilesArea.css({ width: profilesAreaWidth })
      $('[data-bs-toggle="tooltip"]').tooltip();

      self.stopLoading()
    });
  }


  profile_search_move_up(event) {
    event.preventDefault();
    const $profilesArea = $("#profiles .cards")
    const lastShown = $(".card.profile:not(.hidden)", $profilesArea).last()
    // console.log("last shown: ", lastShown)
    // console.log("last shown index: ", lastShown.index())
    $(".card.profile:not(.hidden):not(.base)", $profilesArea).addClass('hidden')
    lastShown.nextAll().slice(0,8).removeClass('hidden')
    // don't forget to change curent page accordingly
    this.change_current_page("down")
  }
  
  profile_search_move_end(event) {
    event.preventDefault();
    const $profilesArea = $("#profiles .cards")
    const allCards = $(".card.profile:not(.base)", $profilesArea)
    allCards.addClass('hidden')
    const numberCards = allCards.length
    // console.log(`all cards: ${numberCards}`)
    let numberCardsOnLastPage = numberCards % 8
    if (numberCardsOnLastPage == 0){
      numberCardsOnLastPage = 8
    }
    // console.log(`number cards on last page: ${numberCardsOnLastPage}`)
    const shownArea = allCards.slice(numberCards - numberCardsOnLastPage, numberCards)
    // console.log("shown Area: ", shownArea)
    shownArea.removeClass('hidden')
    // don't forget to change curent page accordingly
    this.change_current_page("end")
  }
  
  profile_search_move_down(event) {
    event.preventDefault();
    const $profilesArea = $("#profiles .cards")
    const firstShown = $(".card.profile:not(.hidden):not(.base)", $profilesArea).first()
    // console.log("first shown: ", firstShown)
    // console.log("first shown index: ", firstShown.index())
    $(".card.profile:not(.hidden):not(.base)", $profilesArea).addClass('hidden')
    const prevLastHidden = firstShown.prevAll()
    // console.log("prev last hidden index: ", prevLastHidden.first().index())
    const shownArea = prevLastHidden.slice(0,8)
    // console.log("shown area: ", shownArea)
    // check if on page 1 we have less profiles and stuff if necessary
    const numberProfiles = shownArea.length
    if (numberProfiles < 8) {
      $.merge(shownArea,firstShown.nextAll().addBack().slice(0, (9 - numberProfiles)))
    }
    shownArea.removeClass('hidden')
    // don't forget to change curent page accordingly
    this.change_current_page("up")
  }

  profile_search_move_start(event) {
    event.preventDefault();
    const $profilesArea = $("#profiles .cards")
    const allCards = $(".card.profile:not(.base)", $profilesArea)
    allCards.addClass('hidden')
    const shownArea = allCards.slice(0,8)
    // console.log("shown Area: ", shownArea)
    shownArea.removeClass('hidden')
    // don't forget to change curent page accordingly
    this.change_current_page("start")
  }
  

  ///
  // changes the current page visualization
  // as well as it disables the prev / next buttons on the edges
  ///
  change_current_page(dir) {
    let currentPage = parseInt($("#current_page").text())
    // console.log(`current page: ${currentPage}`)
    let allPages = parseInt($("#all_pages").text())
    // console.log(`all pages: ${allPages}`)
    switch (dir) {
      case "up":
        currentPage -= 1
        break;
      case "down":
        currentPage += 1
        break;
      case "end":
        currentPage = allPages
        break;
      case "start":
        currentPage = 1
        break;
    }
    $("#current_page").text(currentPage)

    if (currentPage == 1) {
      $("#pagination .pagination-mover.down").prop('disabled', true)
      $("#pagination .pagination-mover.up").prop('disabled', false)
    } else if (currentPage == allPages) {
      // console.log("we are at the upper end")
      $("#pagination .pagination-mover.up").prop('disabled', true)
      $("#pagination .pagination-mover.down").prop('disabled', false)
    } else {
      $("#pagination .pagination-mover").prop('disabled', false)
    }
  }

  ///
  // renders the profiles modal for the selected ids 
  ///
  profile_search_selection(event) {
    event.preventDefault();
    this.startLoading()
    // also get the skills and their names
    let skillNames = {}
    $("#skills tr.skill td").each(function (index) {
      skillNames[$(this).data("skill-id")] = $(this).text()
    })
    // console.log("skills: ", skillNames)
    this.stimulate('Skill#render_profiles', this.baseRatingIdValue, this.selectedProfiles, skillNames).then(() => {
      $("#selection_modal").modal("show")
      this.stopLoading()
    });
  }

  ///
  // called whenever a profile in profile saerch results is selecetd by the check box at the bottom
  ///
  profile_checked(event) {
    event.preventDefault();
    const $checkbox = $(event.currentTarget)
    const profileId = $checkbox.val()
    // console.log(`profile selected (${profileId})...`)
    if ($checkbox.prop("checked")) {
      // console.log("check it")
      this.selectedProfiles.push($checkbox.val())
      $("#profile_selection_button").prop("disabled", false)
    } else {
      // console.log("uncheck it")
      const index = self.selectedProfiles.indexOf(profileId);
      this.selectedProfiles.splice(index, 1);
      if (this.selectedProfiles.length == 0) {
        $("#profile_selection_button").prop("disabled", true)
      }
    }
  }

}
