import { Controller } from '@hotwired/stimulus'
import StimulusReflex from 'stimulus_reflex'
import { getMetaValue } from '../packs/global_functions'
import Logger from '../packs/logger'

export default class ApplicationController extends Controller {


  logger = new Logger()

  ///
  // set this.useReflex = false, if the controller has non need for Reflex
  ///
  initialize() {
    this.useReflex = true
    // this.logInfo("initialized")
  }

  connect() {
    // this.logInfo("connected")
    if(this.useReflex) {
      StimulusReflex.register(this)
    }
  }

  disconnect() {
    this.logInfo("disconnected")
  }

  logInfo(message) {
    this.logger.info(this.identifier, message)
  }

  logWarning(message) {
    this.logger.warn(this.identifier, message)
  }

  logError(message) {
    this.logger.error(this.identifier, message)
  }

  logMessage(message, title = null, severity = 'info') {
    message = `${title != null ? title + ': ' : ''}${message}`

    switch(severity) {
      case 'warning':
        this.logWarning(message)
        break; 
      case 'error': 
        this.logError(message)
        break;
      default:
        this.logInfo(message)
    }
  }

  // shows the rotating spinner
  startLoading() {
    $("#loading").addClass('loading')
  }

  // hides the rotating spinner
  stopLoading() {
    $("#loading").removeClass('loading')
  }

  getBaseUrl() {
    return window.location.protocol + "//" + window.location.host + window.location.pathname;
  }

  ///
  // takes csrf token from head and resets the hidden input field to this value in the form
  // this is needed for example when the form is rerendered by a reflex
  ///
  reset_csrf_token() {
    $("[name=authenticity_token]").val(getMetaValue("csrf-token"));
  }

  create_authenticy_token_for(name) {
    if($(name).find("[name=authenticity_token]").length <= 0) {
      $("<input type='hidden' name='authenticity_token'></input>").appendTo($(name))
    }
  }

  setupTooltips() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    this.tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, { boundary: "window" })
    })

  }

  ///
  // hides all tooltips on page
  ///
  hideTooltips() {
    this.tooltipList.map(function (tooltip) {
      tooltip.hide();
    });

  }

  ///
  // sets up the bs tooltip toggle again
  // should be called after reflex morphs
  ///
  reloadTooltips() {
    this.hideTooltips();
    this.setupTooltips();
  }

  ///
  // can be called in :connect to call different initializers, which are defined by the init-value
  // this functionality needs this.initializers to be set to an appropriate hash like { simple: this.initClickListeners, in_page: this.initInPage} 
  ///
  callInitializers() {
    const self = this
    let initMethods = this.initValue.split(" ")
    if(initMethods[0] == "") {
      initMethods = [] 
    }
    // console.log("initMethods: ", initMethods)
    initMethods.forEach(function (initializer, index) {
      self.initializers[initializer].bind(self)()
    })

  }

  /// returns the value of parameter with given name
  getParam(paramName) {
    return new URLSearchParams(window.location.search).get(paramName)
  }

}